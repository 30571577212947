.passwordHide:hover{
    color:#f0c600;
    
}
.lognInBtn{
    background-color:#f0c600;
    color:black;
    transition: all ease 0.5s;
    border-radius:4px;
}
.lognInBtn:hover{
  background-color:#f0c600;
  box-shadow: 0px 15px 17px -8px rgba(0, 0, 0, 0.5); 
  color: #333;
  transform: translateY(-10px);
  
}
.lead{
  font-size: large;
  text-align: left;
}