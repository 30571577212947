.font12 {
  font-size: 12px !important;
}

.cursorPointer {
  cursor: pointer !important;
}

.pac-container {
  z-index: 10000 !important;
}

.strikeThrough {
  text-decoration: line-through;
}

.cardHeading {
  padding: 10px;
}
